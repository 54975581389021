import React, { useEffect, useState } from 'react';
import Layout from './../../components/layout';
import SEO from './../../components/seo';
import Header from './../../components/layouts/header';
import { LinearProgress } from '@material-ui/core';
import { getProjectById } from '../../utils/functions';
import { IProject } from '../../utils/interfaces';
import { CMS_ROUTE } from '../../utils/constants';
import './item.css';

const item = () => {
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState<undefined | IProject>();

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const params = new URLSearchParams(location.search);
                const projectId = params.get("id");
                setProject(await getProjectById(projectId))
            } catch (error) {
                alert(error);
            } finally {
                setLoading(false);
            }
        }

        getData().then(() => {
            // A todos los enlaces les coloco noreferrer, noopener y target _blank
            const portfolioItem = document.getElementById("portfolioItem");
            const anchors = portfolioItem.getElementsByTagName("a");
            for (let i = 0; i < anchors.length; i++) {
                const anchor = anchors[i];
                anchor.rel = "noreferrer noopener";
                anchor.target = "_blank";
            }
        })
    }, []);

    if (loading) return <LinearProgress style={{width: "100%"}} />
    return (
        <Layout page={`NodeAxan | ${project.project_name}`}>
            <SEO title={`NodeAxan | ${project.project_name}`} />

            <div>
                <Header siteTitle={project.project_name} />
            </div>

            <main>
                <div id="portfolioItem" dangerouslySetInnerHTML={{__html: project.body}}></div>
            </main>
        </Layout>
    );
};

export default item;