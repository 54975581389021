import { Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {
  return (
      <Typography component="header" variant='h2' color='primary'>{siteTitle}</Typography>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
